var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tracking.length != 0
        ? _c(
            "vs-table",
            {
              attrs: { "max-items": "6", pagination: "", data: _vm.tracking },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var data = ref.data
                      return _vm._l(data, function(tr, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr },
                          [
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].creationDate } },
                              [_vm._v(_vm._s(data[indextr].creationDate))]
                            ),
                            _c(
                              "vs-td",
                              {
                                attrs: { data: data[indextr].previousStageName }
                              },
                              [_vm._v(_vm._s(data[indextr].previousStageName))]
                            ),
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].newStageName } },
                              [_vm._v(_vm._s(data[indextr].newStageName))]
                            )
                          ],
                          1
                        )
                      })
                    }
                  }
                ],
                null,
                false,
                1555093885
              )
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { align: "center" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Date.i18n) ||
                          _vm.t(_vm.resources.Date.name)
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.PreviousStage.i18n) ||
                          _vm.t(_vm.resources.PreviousStage.name)
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.CurrentStage.i18n) ||
                          _vm.t(_vm.resources.CurrentStage.name)
                      )
                    )
                  ])
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }