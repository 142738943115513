<template>
  <div class="mt-base">
    <div
      v-if="purchaseId != '' && purchaseId != undefined"
      class="mb-base mt-4"
    >
      <div class="vx-row">
        <vs-input
          class="vx-col w-full md:w-1/2 mb-2 inputx"
          :label="$t(resources.Subject.i18n) || t(resources.Subject.name)"
          v-model="subject"
        />
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="btn btn-primary" style="margin-right: 80px">{{
            $t(resources.Attachment.i18n) || resources.Attachment.name
          }}</label>
          <vs-input type="file" class="w-full" @change="onFileSelected" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label>{{
            $t(resources.Remark.i18n) || resources.Remark.name
          }}</label>
          <vs-textarea v-model="remark" height="150" maxlength="8000" />
        </div>
        <div class="vx-col mt-base">
          <vs-button
            color="primary"
            type="filled"
            class="mb-4"
            @click="create()"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </div>
    <vx-timeline :data="comments" />
  </div>
</template>
<script>
import database from "@/assets/resources/enums/database.json";
import other from "@/assets/resources/enums/other.json";
import resources from "@/i18n/resources.js";
import vSelect from "vue-select";
import axios from "axios";
import VxTimeline from "@/components/timeline/VxTimeline";

export default {
  components: {
    vSelect,
    VxTimeline,
  },
  name: "comments",
  props: {
    purchaseId: String,
    currentStage: Number,
    purchaseType: Number,
  },
  data() {
    return {
      comments: [],
      database: database,
      resources: resources,
      other: other,
      remark: "",
      subject: "",
      publish: false,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      file: "",
    };
  },
  methods: {
    async get() {
      this.comments = [];
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Remarks/Search`,
        data: {
          searchKeys: {
            identifier: this.purchaseId,
            entityId: this.other.entity.purchase,
            ispublish: true,
          },
          page: 1,
          rows: 20,
          language: this.$i18n.locale,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.comments = [];
          } else {
            let response = result.data.data;
            for (var i = 0; i < response.length; i++) {
              var color = "warning";

              if (response[i].type == 0 && response[i].publish)
                color = "success";
              else if (response[i].type == 0 && !response[i].publish)
                color = "danger";
              else if (response[i].type == 2 && response[i].publish)
                color = "primary";

              this.comments.push({
                id: i,
                color: color,
                icon: response[i].type == 1 ? "MailIcon" : "MessageSquareIcon",
                title: response[i].subject,
                desc: response[i].remark,
                time: response[i].creationDate,
                user: response[i].createdBy,
                type: response[i].type,
                url: response[i].pathUrl,
              });
            }
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async create() {
      let formData = new FormData();
      formData.set("identifier", this.purchaseId);
      formData.set("entityId", this.other.entity.purchase);
      formData.set("remark", this.remark);
      formData.set("subject", this.subject);
      formData.set("type", 2);
      formData.set("createdBy", this.email);
      formData.set("sender", this.email);
      formData.set("language", this.$i18n.locale.toUpperCase());
      formData.set("publish", true);
      if (this.file != null && this.file != "")
        formData.append("file", this.file, this.file.Name);

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Remarks`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data",
        },
      }).then(
        async (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("MsgSaved"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.subject = "";
            this.remark = "";
            this.file = "";
            await this.sendEmail(this.purchaseId);
            await this.get();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async sendEmail(purchaseId) {
      let emails = ["odalis@visiontravel.net", "v.hernandez@visiontravelmail.net"];

      let url = "";
      let desc = "";
      if (this.purchaseType == this.database.purchaseType.membership) {
        url = `https://foca.visiontravel.net/purchases/membership/${purchaseId}`;
        desc = "Afiliación";
      } else if (
        this.purchaseType == this.database.purchaseType.monthlyPayment
      ) {
        url = `https://foca.visiontravel.net/purchases/monthlyPayment/${purchaseId}`;
        desc = "Mensualidad";
      } else if (this.purchaseType == this.database.purchaseType.upgrade) {
        url = `https://foca.visiontravel.net/purchases/upgrade/${purchaseId}`;
        desc = "Upgrade";
      }

      let _data = {
        subject: `[${desc}] Comentario añadido`,
        message: `<p>Se ha añadido un comentario en el ID de la Compra ${purchaseId}.</p><p>Para ver el detalle de la compra haga clic <a href="${url}"><strong>AQUÍ</strong></a>.</p>`,
        language: this.$i18n.locale.toUpperCase(),
        createdBy: this.email,
        productType: 2,
        identifier: purchaseId,
        addressesList: emails,
        entityId: this.other.entity.purchase,
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Emails/Template`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`,
        },
      });
    },

    onFileSelected(event) {
      this.file = event.target.files[0];
    },
  },
  async created() {
    await this.get();
  },
};
</script>