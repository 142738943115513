<template>
  <div>
    <vx-card
      v-if="installment > 1"
      slot="no-body"
      class="text-center bg-primary-gradient greet-user mb-4"
    >
      <img
        src="@/assets/images/elements/decore-left.png"
        class="decore-left"
        alt="Decore Left"
        width="150"
      />
      <vs-icon icon-pack="material-icons" icon="mood" size="large" color="rgb(255,255,255)"></vs-icon>
      <h3
        class="font-semibold text-white text-center mb-2"
      >{{$t(resources.InstallmentPay.i18n) || resources.InstallmentPay.name}}:</h3>
      <h3 class="font-semibold text-white text-center">{{getMinimum}}</h3>
    </vx-card>
    <vx-card>
      <vs-row vs-type="flex" vs-justify="center" class="mb-base">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <img v-if="data.length > 0" :src="data[0].imageUrl" @error="imageUrlAlt" width="160" />
        </vs-col>
      </vs-row>
      <div class="flex justify-between mb-2" v-if="getTaxes != 0">
        <span>{{$t(resources.Tax.i18n) || resources.Tax.name}}</span>
        <span class="text-right">{{getTaxes}}</span>
      </div>
      <div v-for="(item, index) in data" :key="index">
        <div class="flex justify-between mb-2">
          <span>{{item.productName}}</span>
          <span
            class="text-right"
            v-if="item.orderType == other.billingOrder.product"
          >{{item.currencyCode}} {{item.productValue}}&nbsp;</span>
          <span
            class="text-danger text-right"
            v-if="item.orderType == other.billingOrder.wayToPay"
          >- {{item.currencyCode}} {{item.productValue}}</span>
          <span
            class="text-danger text-right"
            v-if="item.orderType == other.billingOrder.notValidPayment"
          >({{$t(resources.Declined.i18n) || resources.Declined.name}}) {{item.currencyCode}} {{item.productValue}}</span>
        </div>
      </div>
      <!-- <div class="flex justify-between mb-2" v-if="getTaxes != 0">
        <span>{{$t(resources.Tax.i18n) || resources.Tax.name}}</span>
        <span class="text-right">{{getTaxes}}</span>
      </div>-->

      <vs-divider />
      <div class="flex justify-between mb-2 mt-2">
        <h3 class="font-semibold text-primary">{{$t(resources.Total.i18n) || resources.Total.name}}</h3>
        <h3 class="font-semibold text-primary">{{getTotal}}</h3>
      </div>
    </vx-card>
  </div>
</template>
<script>
import other from "@/assets/resources/enums/other.json";
import resources from "@/i18n/resources.js";

export default {
  name: "billing",
  props: {
    data: {
      type: Array,
      required: true,
    },
    installment: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      resources: resources,

      other: other,
      products: [],
      total: 0,
      installmentValue: 0,
    };
  },
  computed: {
    getTotal() {
      let _wayToPayTotal = 0;
      let _productTotal = 0;
      if (this.data.length > 0) {
        if (
          this.data.filter(
            (x) => x.orderType == this.other.billingOrder.wayToPay
          ).length > 0
        ) {
          _wayToPayTotal = this.data
            .filter((x) => x.orderType == this.other.billingOrder.wayToPay)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }

        if (
          this.data.filter(
            (x) => x.orderType == this.other.billingOrder.product
          ).length > 0
        ) {
          _productTotal = this.data
            .filter((x) => x.orderType == this.other.billingOrder.product)
            .reduce(
              (sum, current) =>
                sum +
                parseFloat(current.productValue) +
                parseFloat(current.productTax),
              0
            );
        }
        let _total =
          parseFloat(_productTotal).toFixed(2) -
          parseFloat(_wayToPayTotal).toFixed(2);

        return `${this.data[0].currencyCode} ${_total.toFixed(2)}`;
      } else return "0.00";
    },

    getMinimum() {
      let total = 0;
      let products = this.data.filter(
        (x) => x.orderType == this.other.billingOrder.product
      );

      for (let index = 0; index < products.length; index++) {
        total +=
          (products[index].productValue + products[index].productTax) /
          this.installment;
      }
      return `${this.data[0].currencyCode} ${parseFloat(total).toFixed(2)}`;
    },

    getTaxes() {
      let _productTotal = 0;
      if (
        this.data.filter((x) => x.orderType == this.other.billingOrder.product)
          .length > 0
      ) {
        _productTotal = this.data
          .filter((x) => x.orderType == this.other.billingOrder.product)
          .reduce((sum, current) => sum + parseFloat(current.productTax), 0);
        return `${this.data[0].currencyCode} ${parseFloat(
          _productTotal
        ).toFixed(2)}`;
      }
      return _productTotal;
    },
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src =
        "https://cdn1.visiontravel.net/Images/products/VIVE001.png";
    },
  },
};
</script>