var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.installment > 1
        ? _c(
            "vx-card",
            {
              staticClass: "text-center bg-primary-gradient greet-user mb-4",
              attrs: { slot: "no-body" },
              slot: "no-body"
            },
            [
              _c("img", {
                staticClass: "decore-left",
                attrs: {
                  src: require("@/assets/images/elements/decore-left.png"),
                  alt: "Decore Left",
                  width: "150"
                }
              }),
              _c("vs-icon", {
                attrs: {
                  "icon-pack": "material-icons",
                  icon: "mood",
                  size: "large",
                  color: "rgb(255,255,255)"
                }
              }),
              _c(
                "h3",
                { staticClass: "font-semibold text-white text-center mb-2" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.InstallmentPay.i18n) ||
                        _vm.resources.InstallmentPay.name
                    ) + ":"
                  )
                ]
              ),
              _c(
                "h3",
                { staticClass: "font-semibold text-white text-center" },
                [_vm._v(_vm._s(_vm.getMinimum))]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "vx-card",
        [
          _c(
            "vs-row",
            {
              staticClass: "mb-base",
              attrs: { "vs-type": "flex", "vs-justify": "center" }
            },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center"
                  }
                },
                [
                  _vm.data.length > 0
                    ? _c("img", {
                        attrs: { src: _vm.data[0].imageUrl, width: "160" },
                        on: { error: _vm.imageUrlAlt }
                      })
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm.getTaxes != 0
            ? _c("div", { staticClass: "flex justify-between mb-2" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Tax.i18n) || _vm.resources.Tax.name
                    )
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.getTaxes))
                ])
              ])
            : _vm._e(),
          _vm._l(_vm.data, function(item, index) {
            return _c("div", { key: index }, [
              _c("div", { staticClass: "flex justify-between mb-2" }, [
                _c("span", [_vm._v(_vm._s(item.productName))]),
                item.orderType == _vm.other.billingOrder.product
                  ? _c("span", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(item.currencyCode) +
                          " " +
                          _vm._s(item.productValue) +
                          " "
                      )
                    ])
                  : _vm._e(),
                item.orderType == _vm.other.billingOrder.wayToPay
                  ? _c("span", { staticClass: "text-danger text-right" }, [
                      _vm._v(
                        "- " +
                          _vm._s(item.currencyCode) +
                          " " +
                          _vm._s(item.productValue)
                      )
                    ])
                  : _vm._e(),
                item.orderType == _vm.other.billingOrder.notValidPayment
                  ? _c("span", { staticClass: "text-danger text-right" }, [
                      _vm._v(
                        "(" +
                          _vm._s(
                            _vm.$t(_vm.resources.Declined.i18n) ||
                              _vm.resources.Declined.name
                          ) +
                          ") " +
                          _vm._s(item.currencyCode) +
                          " " +
                          _vm._s(item.productValue)
                      )
                    ])
                  : _vm._e()
              ])
            ])
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "flex justify-between mb-2 mt-2" }, [
            _c("h3", { staticClass: "font-semibold text-primary" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Total.i18n) || _vm.resources.Total.name
                )
              )
            ]),
            _c("h3", { staticClass: "font-semibold text-primary" }, [
              _vm._v(_vm._s(_vm.getTotal))
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }