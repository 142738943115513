<template>
  <div>
    <vs-table v-if="tracking.length != 0" max-items="6" pagination :data="tracking">
      <template slot="thead">
        <vs-th align="center">{{$t(resources.Date.i18n) || t(resources.Date.name)}}</vs-th>
        <vs-th>{{$t(resources.PreviousStage.i18n) || t(resources.PreviousStage.name)}}</vs-th>
        <vs-th>{{$t(resources.CurrentStage.i18n) || t(resources.CurrentStage.name)}}</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].creationDate">{{ data[indextr].creationDate }}</vs-td>
          <vs-td :data="data[indextr].previousStageName">{{ data[indextr].previousStageName }}</vs-td>
          <vs-td :data="data[indextr].newStageName">{{ data[indextr].newStageName }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import other from "@/assets/resources/enums/other.json";
import resources from "@/i18n/resources.js";
import vSelect from "vue-select";
import axios from "axios";

export default {
  components: {
    vSelect
  },
  name: "tracking",
  props: {
    purchaseId: String,
    currentStage: Number
  },
  data() {
    return {
      tracking: [],
      resources: resources,

      other: other,
      stageId: 0,
      remark: "",
      stageList: [],
      subject: "",
      publish: false,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      file: ""
    };
  },
  methods: {
    async getTracking() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Tracking/${this.$i18n.locale}/${this.purchaseId}/${this.other.entity.purchase}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.tracking = [];
          } else {
            this.tracking = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  async created() {
    await this.getTracking();
  }
};
</script>