var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-base" },
    [
      _vm.purchaseId != "" && _vm.purchaseId != undefined
        ? _c("div", { staticClass: "mb-base mt-4" }, [
            _c(
              "div",
              { staticClass: "vx-row" },
              [
                _c("vs-input", {
                  staticClass: "vx-col w-full md:w-1/2 mb-2 inputx",
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Subject.i18n) ||
                      _vm.t(_vm.resources.Subject.name)
                  },
                  model: {
                    value: _vm.subject,
                    callback: function($$v) {
                      _vm.subject = $$v
                    },
                    expression: "subject"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-2" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "btn btn-primary",
                        staticStyle: { "margin-right": "80px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Attachment.i18n) ||
                              _vm.resources.Attachment.name
                          )
                        )
                      ]
                    ),
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "file" },
                      on: { change: _vm.onFileSelected }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/2 w-full" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Remark.i18n) ||
                          _vm.resources.Remark.name
                      )
                    )
                  ]),
                  _c("vs-textarea", {
                    attrs: { height: "150", maxlength: "8000" },
                    model: {
                      value: _vm.remark,
                      callback: function($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col mt-base" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-4",
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.create()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(_vm.resources.Save.i18n) ||
                              _vm.resources.Save.name
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("vx-timeline", { attrs: { data: _vm.comments } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }